import {
	AfterViewInit,
	Component,
	inject, OnDestroy,
	OnInit, TemplateRef, viewChild,
	ViewChild,
} from '@angular/core';
import { FileSystemService } from '../file-system.service';
import {DEFAULT_SEARCH_CONFIG, FileMetadata} from '../../model';
import { rowsAnimation } from '../../animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject, merge, of as observableOf } from 'rxjs';
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import {
	FILES_CATEGORIES_COLORS,
	FILES_CATEGORIES_ICONS,
	FILES_COLUMNS,
} from './table-conf';
import { union } from 'lodash';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import {SearchService} from "../../shared/advanced-search/search.service";

@Component({
	selector: 'ft-print-files',
	templateUrl: './print-files.component.html',
	styleUrls: ['./print-files.component.scss'],
	animations: [rowsAnimation],
})
export class PrintFilesComponent implements OnInit, AfterViewInit, OnDestroy {
	public files: FileMetadata[] = [];
	public dataSource = new MatTableDataSource<FileMetadata>();
	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;

	displayedColumns = [];
	columnsToDisplay = [];
	availableColumns = [];

	filterChange = new BehaviorSubject('');
	filterForm!: FormGroup;

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	private _fs = inject(FileSystemService);
	private _fb = inject(FormBuilder);

	searchTemplate = viewChild.required<TemplateRef<any>>('searchTemplate');
	private _searchService = inject(SearchService);

	constructor() {
		setTimeout(() => {
			this._searchService.searchInputConfig.set({
				placeholder: 'search.printing',
				expandable:true,hidden:false,
				template: this.searchTemplate()
			});

			this._searchService.genericSearchObs.subscribe(value => this.filterForm.get('key').patchValue(value));
		});
	}

	ngOnDestroy() {
		this._searchService.searchInputConfig.set(DEFAULT_SEARCH_CONFIG);
	}

	ngOnInit(): void {
		this.filterForm = this._fb.group({
			key: '',
			startDate: null,
			endDate: null,
			category: [],
		});

		this.filterForm.valueChanges
			.pipe(debounceTime(400), distinctUntilChanged())
			.subscribe(value => {
				const { key, startDate, endDate, category } = value;
				let dateRange = `20230830_${moment().format('YYYYMMDD')}`;
				if (startDate && endDate)
					dateRange = `${moment(startDate).format('YYYYMMDD')}_${moment(endDate).format('YYYYMMDD')}`;

				const categories = category?.join('_');

				const query = [key, dateRange, categories].join('@');

				if (!this.dataSource) return;
				this.paginator.pageIndex = 0;

				this.filterChange.next(query);
			});

		this.displayedColumns = FILES_COLUMNS;
		this.availableColumns = FILES_COLUMNS;
		this.columnsToDisplay = union(
			this.availableColumns.map(it => it.label),
			['action']
		);
	}

	trackByName = (_: number, item: any): string => item.name;

	ngAfterViewInit(): void {
		this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

		merge(
			this.sort.sortChange.asObservable(),
			this.paginator.page.asObservable(),
			this.filterChange
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;

					let value = this.filterChange.getValue();
					if (!value || value === '')
						value = `@20230829_${moment().format('YYYYMMDD')}@`;

					return this._fs.getAllFiles(
						this.paginator.pageSize,
						this.paginator.pageIndex,
						this.sort.active,
						this.sort.direction,
						value
					);
				}),
				map(data => {
					this.isLoadingResults = false;
					this.isRateLimitReached = false;
					this.resultsLength = data['totalElements'];
					return data['content'];
				}),
				catchError(() => {
					this.isLoadingResults = false;
					this.isRateLimitReached = true;
					return observableOf([]);
				})
			)
			.subscribe(data => (this.dataSource.data = data));
	}

	download(file: FileMetadata): void {
		this._fs.download(file.filepath).subscribe();
	}

	print(file: FileMetadata): void {
		this._fs.print(file.filepath).subscribe();
	}

	open(file: FileMetadata): void {
		this._fs.open(file.filepath).subscribe();
	}

	protected readonly FILES_CATEGORIES_ICONS = FILES_CATEGORIES_ICONS;
	protected readonly FILES_CATEGORIES_COLORS = FILES_CATEGORIES_COLORS;
}
