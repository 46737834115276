import { Component, OnInit, ViewChild } from '@angular/core';
import {
	ExamStateView,
	MONTHS,
	PatientStateView,
	PaymentStateView,
	StatisticItem,
	ViewQuery,
	VIEWS,
} from '../model';
import moment from 'moment';
import { StateDirective } from '../state.directive';
import { StatisticComponent } from '../statistic.component';
import { range } from 'lodash';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateUtils } from '../../utils';
import { SharedService } from '../../shared';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { GeneralSettingDTO, Profile, UserAccountDTO } from '../../model';
import { StatisticService } from '../statistic.service';
import FileSaver from 'file-saver';
import { AppConfigService } from '../../app-config.service';

@Component({
	selector: 'ft-exam-per-month',
	templateUrl: './exam-per-month.component.html',
	styleUrls: ['./exam-per-month.component.scss'],
})
export class ExamPerMonthComponent implements OnInit {
	months = MONTHS;
	years = range(2018, 2022);

	selectedDate = new Date();
	days = range(1, this.selectedDate.getDate() + 1);

	selectedDataType = 'EXAMS';

	views = VIEWS;
	@ViewChild(StateDirective, { static: true }) ftState: StateDirective;
	private viewQuery = ViewQuery.create(this.selectedDate);
	currentView: string;
	currentState: string;
	queryForm: FormGroup;
	users: UserAccountDTO[] = [];
	boxes = [];
	startDateLabel = 'FROM';
	referringPhysicianControl = new FormControl();
	filteredReferringPhysicians: Array<any[]> = [];
	physicians: Array<any> = [];
	technicians: Array<any> = [];
	currentPhysicianId: any;
	profile: Profile;
	exportButtonVisible: boolean = false;
	downloading = false;
	private readonly generalSetting: GeneralSettingDTO;
	public filterVisible: boolean = true;

	constructor(
		private fb: FormBuilder,
		private shared: SharedService,
		private _config: AppConfigService,
		private _statisticService: StatisticService,
		private route: ActivatedRoute
	) {
		this.profile = this.route.snapshot.data['user'].profile;

		this.generalSetting = this._config.generalSetting;

		this.queryForm = this.fb.group(new ViewQuery());
		this.shared
			.getUsersWithPaymentRight()
			.subscribe(res => (this.users = res));

		const datasets = 'boxes,performingPhysicians,technicians';
		this.shared.getDatasets(datasets).subscribe(data => {
			const { boxes, performingPhysicians, technicians } = data;
			this.physicians = performingPhysicians;
			this.technicians = technicians;
			this.boxes = boxes;
		});

		this.referringPhysicianControl.valueChanges
			.pipe(
				startWith(''),
				switchMap(() => {
					const query = this.referringPhysicianControl.value;
					return this.shared.queryReferringPhysicians(
						10,
						0,
						'lastName',
						'asc',
						query
					);
				}),
				map(data => data['content']),
				catchError(() => {
					return observableOf([]);
				})
			)
			.subscribe(data => (this.filteredReferringPhysicians = data));
	}

	ngOnInit() {
		if (this.generalSetting) {
			const split =
				this.generalSetting && this.generalSetting.statisticDefaultView
					? this.generalSetting.statisticDefaultView.split('@')
					: ['EXAM', 'GENERAL_VIEW'];
			const ste = split[0];
			const view = split[1];
			this.selectView(view, ste);
			this.startDateLabel =
				this.currentState === 'PAYMENT' ? 'DATE' : 'FROM';
			this.queryForm.valueChanges.subscribe(query =>
				this.updateQuery(query)
			);

			this.referringPhysicianControl.patchValue('');
		}
	}

	updateQuery(query: ViewQuery) {
		this.viewQuery = query;
		if (
			this.currentView === ExamStateView.REFERRING_PHYSICIAN ||
			this.currentView === ExamStateView.PERFORMING_RADIOLOGIST ||
			this.currentView === ExamStateView.TECHNICIANS
		)
			this.viewQuery.filter = this.currentPhysicianId;
		if (moment(query.startDate).isAfter(moment(query.endDate), 'd')) {
			this.viewQuery.startDate = this.viewQuery.endDate;
			this.queryForm
				.get('startDate')
				.patchValue(this.viewQuery.startDate);
		}

		if (this.currentState === 'PAYMENT') {
			if (this.viewQuery.filter2 === 'BOX') {
				const box = this.boxes.find(
					it => it.name === this.viewQuery.filter
				);
				this.viewQuery.filter = box ? box.id : '';
			}
			if (this.viewQuery.filter2 === 'USER') {
				const user = this.users.find(
					it => it.fullName === this.viewQuery.filter
				);
				this.viewQuery.filter = user ? user.id : '';
			}
		}

		setTimeout(
			() => this.selectView(this.currentView, this.currentState),
			200
		);
	}

	selectView(view: string, state: string, reset: boolean = false) {
		if (reset) this.currentPhysicianId = null;
		this.selectedDataType = `${state}S`;
		this.currentView = view;
		this.currentState = state;
		this.exportButtonVisible = [
			ExamStateView.PERFORMING_RADIOLOGIST,
			ExamStateView.TECHNICIANS,
		].includes(ExamStateView[view]);
		this.filterVisible = ExamStateView.PRINT_FILES !== ExamStateView[view];
		this.loadComponent(
			StatisticItem.load(
				StatisticComponent.COMPONENTS[state][view],
				this.viewQuery
			)
		);
	}

	private loadComponent(stateItem: StatisticItem) {
		const vcRef = this.ftState.viewContainerRef;
		vcRef.clear();

		vcRef.createComponent(stateItem.component).instance.data =
			stateItem.data;
	}

	updateDateRange(period: any) {
		const dateRange = DateUtils.PeriodDateRange(period);
		this.queryForm.get('startDate').patchValue(dateRange.startDate);
		this.queryForm.get('endDate').patchValue(dateRange.endDate);
	}

	onChangeRefPhy(event: any) {
		const physician = event.option.value;
		this.referringPhysicianControl.patchValue(physician.fullName);
		this.viewQuery.filter = physician.id;
		this.currentPhysicianId = physician.id;
		this.updateQuery(this.viewQuery);
	}

	onChangeUser(user: any) {
		this.viewQuery.filter = user.id;
		this.currentPhysicianId = user.id;
		this.updateQuery(this.viewQuery);
	}

	clearInput(event: any) {
		event.preventDefault();
		event.stopPropagation();
		this.currentPhysicianId = '';
		this.viewQuery.filter = '';
		this.referringPhysicianControl.patchValue('');
		this.updateQuery(this.viewQuery);
	}

	exportData(toType: 'PDF' | 'EXCEL'): void {
		this.downloading = true;
		const startDate = moment(this.viewQuery.startDate).format('YYYYMMDD');
		const endDate = moment(this.viewQuery.endDate).format('YYYYMMDD');
		const userType =
			this.currentView === ExamStateView.PERFORMING_RADIOLOGIST
				? 'PHYSICIAN'
				: 'TECHNICIAN';
		this._statisticService
			.exportData(
				userType,
				this.currentPhysicianId,
				startDate,
				endDate,
				toType
			)
			.subscribe(
				data => {
					this.downloading = false;

					const blob = new Blob([data], {
						type: 'application/vnd.ms-excel',
					});
					const file = new File([blob], 'exported-data.xls', {
						type: 'application/vnd.ms-excel',
					});

					FileSaver.saveAs(file);
				},
				error => (this.downloading = false)
			);
	}

	hasPermission(view: string): boolean {
		switch (view) {
			case ExamStateView.PRINT_FILES:
				return this.profile.showPivotTable === 'ALL';
			case ExamStateView.GENERAL_VIEW:
				return this.profile.showPivotTable === 'ALL';
			case ExamStateView.PERFORMING_RADIOLOGIST:
				return this.profile.showPerformingPhysicianStats === 'ALL';
			case ExamStateView.REFERRING_PHYSICIAN:
				return this.profile.showReferringPhysicianStats === 'ALL';
			case ExamStateView.TECHNICIANS:
				return this.profile.showTechnicianStats === 'ALL';
			case ExamStateView.AET_S:
				return this.profile.showAetStats === 'ALL';
			case ExamStateView.REASONS_FOR_EXAM:
				return this.profile.showExamTypeStats === 'ALL';
			case PatientStateView.PATIENT:
				return this.profile.showPatientStats === 'ALL';
			case PaymentStateView.PAYMENT:
				return this.profile.showPaymentStats === 'ALL';
			case PatientStateView.FUNGIBLE:
				return true;
			case 'EXPORT':
				return this.profile.exportStatistic === 'ALL';
			default:
				return false;
		}
	}
}
